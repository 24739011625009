<!--
 * @Author: your name
 * @Date: 2021-01-29 13:55:28
 * @LastEditTime: 2021-03-30 16:33:07
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \tostumid:\project\web\sxvant\src\views\tos\about.vue
-->
<template>
  <div class="box">
    <h1>About Us</h1>
    <h2>Hello. Welcome to TOS Pte Ltd.</h2>
    <p>
      We are Tiong Hua and William, the founder of TOS. We have been working in
      our individual industry and because of our dreams to pursue
      digitalization, we set up TOS in early 2019.
    </p>
    <p>
      We purchase in later 2019 Corporate Real Estate Management Services Pte
      Ltd (CREMS) and now we will incorporate an Apps for CRMES. We hope with
      the incorporation of the Apps, it will enhance the smooth work flow, cut
      down the manpower for CREMS and more importantly the convenience for your
      usage.
    </p>
    <p>
      We hope you enjoy our product as much as we enjoy offering it to you. If
      you have any questions or comments, please do not hesitate to contact us.
    </p>
  </div>
</template>

<script>
export default {
  name: "tosAbout",
  data() {
    return {};
  },
};
</script>

<style scoped>
.box {
  min-height: 100vh;
  border: 1px solid #f0f0f0;
  padding: 10px 16px 20px;
  word-break: break-word;
  line-height: 1.6;
  color: #121212;
  box-sizing: border-box;
  text-align: left;
}
.box h1,
.box h2 {
  clear: left;
  margin-top: 1em;
  margin-bottom: 1.16667em;
  font-size: 36px;
  line-height: 1.5;
  font-weight: 600;
  text-align: center;
}
.box h2 {
  font-size: 30px;
  text-align: left;
}
.box p {
  margin: 1.4em 0;
  font-size: 28px;
}
</style>
